@import '../scss/variables';

.skill_wrapper {
  margin: 2rem -1rem;
  display: flex;
  flex-wrap: wrap;
  .skill_item {
    margin-bottom: 3rem;
    width: 50%;
    padding: 0 1rem;
    @media screen and (max-width: 1300px) {
      width: 100%;
    }
    @media screen and (max-width: 1200px) {
      width: 50%;
    }
    @media screen and (max-width: 750px) {
      width: 100%;
    }
    & > div {
      display: flex;
      justify-content: space-between;
      & > div {
        width: calc(100% - 6rem);
      }
    }
    .icon {
      background-color: #ddd;
      border-radius: 0.5rem;
      width: 4.5rem;
      height: 4.5rem;
      line-height: 4.5rem;
      text-align: center;
      margin-top: 0.5rem;
      color: #00b17c;
      font-size: 2rem;
    }
    h5 {
      font-size: 1.25rem;
      margin-bottom: 0.25rem;
    }
    p {
      color: lighten($color: $fontColor, $amount: 20);
      line-height: 1.8;
    }
    ul {
      line-height: 1.6;
      margin-left: 1rem;
    }
  }
}
