@import './variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  @media screen and (max-width: 1200px) {
    font-size: 15px;
  }
  @media screen and (max-width: 992px) {
    font-size: 14px;
  }
  @media screen and (max-width: 576px) {
    font-size: 13.5px;
  }
}

body {
  font-size: 1rem;
  background-color: $white;
  line-height: 1.6;
  color: $fontColor;
  font-family: $ubuntu;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
}
.card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
  @media screen and (max-width: 1200px) {
    display: block;
    margin: 0;
  }
}
.floating-link {
  width: 5rem;
  list-style: none;
  @media screen and (max-width: 1200px) {
    display: none;
  }
  li {
    span {
      cursor: pointer;
      display: block;
      width: 2rem;
      height: 2rem;
      margin: 2rem auto;
      line-height: 2rem;
      text-align: center;
      background-color: transparentize($color: #00b17c, $amount: 0.25);
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #00b17c;
      transition: background-color 0.15s ease-in-out;
      i {
        color: $white;
        transition: color 0.15s ease-in-out;
      }
      &.active,
      &:hover {
        border-color: #00b17c;
        background-color: $white;
        i {
          color: #00b17c;
        }
      }
    }
  }
}
.content-wrapper {
  width: calc(calc(100% - 400px) - 5rem);
  margin: 3rem 0;
  height: calc(100vh - 6rem);
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    width: initial;
    height: initial;
    overflow: initial;
    margin: initial;
  }
  .content {
    background-color: #f7f7f7;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    height: 100%;
    @media screen and (min-width: 1200px) {
      overflow: auto;
      transition: transform 0.5s ease-in-out;
      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background-color: transparentize($color: #00b17c, $amount: 0.9);
        border-radius: 0.25rem;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background-color: #00b17c;
        border-radius: 0.25rem;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background-color: lighten($color: #00b17c, $amount: 10);
      }
    }
    @media screen and (max-width: 1200px) {
      border-radius: 0;
      transform: unset !important;
    }
  }
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: capitalize;
  span {
    color: #00b17c;
  }
}

a {
  color: #00b17c;
  text-decoration: none;
}
