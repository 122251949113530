@import '../scss/variables';

.about_wrapper {
  margin: 2rem 0;
}
.about {
  h3 {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.5rem;
  }
  p {
    margin-bottom: 1rem;
    line-height: 1.6;
    word-spacing: 4px;
    letter-spacing: 1px;
  }
  button {
    margin-top: 1rem;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #00b17c;
    color: $white;
    border-radius: 0.25rem;
    padding: 0.75rem 2.5rem;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-color 0.15s ease;
    &:hover {
      background-color: lighten($color: #00b17c, $amount: 5);
    }
  }
}
.featured_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
  }
  .featured_box {
    width: 25%;
    text-align: center;
    border-right: 2px solid #ddd;
    padding: 1.5rem 0;
    &:last-child {
      border-right: none;
    }
    @media screen and (max-width: 650px) {
      width: 50%;
      &:nth-child(2) {
        border-right: none;
      }
    }
    h1 {
      font-size: 2.5rem;
      @media screen and (max-width: 1360px) {
        font-size: 2.5rem;
      }
      @media screen and (max-width: 1200px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 850px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 400px) {
        font-size: 1.25rem;
      }
    }
    p {
      font-size: 1.25rem;
      color: lighten($color: $fontColor, $amount: 20);
      @media screen and (max-width: 1360px) {
        font-size: 1rem;
      }
      @media screen and (max-width: 1200px) {
        font-size: 1.25rem;
      }
      @media screen and (max-width: 850px) {
        font-size: 1rem;
      }
    }
  }
}
