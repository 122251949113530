@import '../scss/variables';

.contact_wrapper {
  margin: 2rem 0;
  p {
    margin-bottom: 1rem;
    color: lighten($color: $fontColor, $amount: 20);
  }
}

form {
  input,
  textarea {
    background-color: $white;
    border: 1px solid #ddd;
    padding: 0.75rem 1.25rem;
    border-radius: 0.25rem;
    width: 100%;
    font-size: 1rem;
    outline: none;
    font-family: inherit;
    user-select: none;
    &:focus {
      border-color: #00b17c;
    }
  }
  & > div {
    display: flex;
    margin: 0 -0.5rem;
    input {
      margin: 0 0.5rem 1.5rem;
    }
    @media screen and (max-width: 500px) {
      display: block;
      margin: 0;
      input {
        margin: 0 0 1.5rem;
      }
    }
  }
  textarea {
    resize: none;
    margin-bottom: 1.5rem;
  }
  button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #00b17c;
    color: $white;
    border-radius: 0.25rem;
    padding: 0.75rem 2.5rem;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-color 0.15s ease;
    &:hover {
      background-color: lighten($color: #00b17c, $amount: 5);
    }
  }
}

.follow {
  margin-top: 3rem;
  h5 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    display: flex;
    margin: 0 -0.75rem;
    li {
      margin: 0 0.75rem;
      font-size: 1.25rem;
      color: lighten($color: $fontColor, $amount: 20);
    }
  }
}

.more {
  margin-top: 3rem;
  margin-bottom: 2rem;
  h5 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    li {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.5rem;
      color: lighten($color: $fontColor, $amount: 20);
      a {
        color: lighten($color: $fontColor, $amount: 20);
      }
      i {
        color: #00b17c;
      }
    }
  }
}
