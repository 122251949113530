@import '../scss/variables';

.blog_wrapper {
  margin: 2rem 0 0;
  .blog_item {
    margin-bottom: 3rem;
    &:nth-child(even) {
      & > div {
        flex-direction: row-reverse;
      }
    }
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      @media screen and (max-width: 650px) {
        flex-direction: column !important;
        gap: 0.75rem;
      }
      .image {
        width: 40%;
        position: relative;
        @media screen and (max-width: 1300px) {
          width: 30%;
        }
        @media screen and (max-width: 650px) {
          width: 100%;
        }
        img {
          border-radius: 0.5rem;
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      & > div {
        width: 60%;
        @media screen and (max-width: 1300px) {
          width: 70%;
        }
        @media screen and (max-width: 650px) {
          width: 100%;
        }
      }
      .info {
        h5 {
          font-size: 1.25rem;
          font-weight: 700;
          margin-bottom: 1rem;
          @media screen and (max-width: 650px) {
            margin-bottom: 0.5rem;
          }
        }
        p {
          margin-bottom: 1rem;
          color: lighten($color: $fontColor, $amount: 20);
        }
        .read_btn {
          font-size: 0.85rem;
          padding: 0.15rem 0.75rem;
          display: inline-block;
          background-color: transparentize($color: #00b17c, $amount: 0.9);
          color: #00b17c;
          border-radius: 0.25rem;
        }
      }
    }
  }
}
