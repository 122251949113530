@import '../scss/variables';

.aside {
  width: 400px;
  background-color: #f7f7f7;
  padding: 1rem 2rem;
  margin: 3rem 0;
  height: calc(100vh - 6rem);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.user_image {
  padding: 0 0 0;
  img {
    position: relative;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto;
    display: block;
    margin-bottom: 1.5rem;
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: transparentize($color: #00b17c, $amount: 0.75);
    }
  }
  h3 {
    font-weight: 700;
    font-size: 1.75rem;
    text-align: center;
  }
  h6 {
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
  }
}

.user_info {
  padding: 1rem 0 0;
}

.resume_btn {
  text-align: center;
  margin-bottom: 3rem;
  button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #00b17c;
    color: $white;
    border-radius: 0.25rem;
    padding: 0.75rem 2.5rem;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-color 0.15s ease;
    &:hover {
      background-color: lighten($color: #00b17c, $amount: 5);
    }
  }
}

.social {
  h5 {
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    li {
      a {
        display: block;
        font-size: 1.25rem;
        text-align: center;
        border-radius: 50%;
        color: #00b17c;
        margin: 0 1rem;
      }
    }
  }
}
.header {
  @media screen and (min-width: 1200px) {
    display: none;
  }
  background-color: #111418;
  padding: 1rem 2rem;
  .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left {
    h2 {
      font-size: 2rem;
      color: $white;
    }
  }
  .right {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    .social {
      list-style: none;
      display: flex;
      a {
        color: darken($color: $white, $amount: 20);
        display: inline-block;
        margin: 0 0.75rem;
      }
    }
    .menu_btn {
      margin-left: 1rem;
      color: $white;
      font-size: 1.5rem;
      cursor: pointer;
      display: none;
    }
    .resume_btn {
      text-align: center;
      
      button {
        cursor: pointer;
        border: none;
        outline: none;
        background-color: #00b17c;
        color: $white;
        border-radius: 0.25rem;
        padding: 0.2rem .65rem;
        font-size: 1rem;
        font-family: inherit;
        font-weight: 700;
        text-transform: uppercase;
        transition: background-color 0.15s ease;
        &:hover {
          background-color: lighten($color: #00b17c, $amount: 5);
        }
      }
    }
  }
}